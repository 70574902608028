@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';


.notifier{
    @include absoluteCenterBlock;
    color: $colorMainYellow;
    padding: 10px;
    border-radius: 5px;
    word-break: break-word;
    &[data-type='info']{
        background-color: $colorMainBlue;
    }
    &[data-type='error']{
        background-color: $colorError;
    }
}

