@import '../../../../styles/variables.scss';

.tray {
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $colorMainYellow;
  color: $colorMainBlue;

  display: flex;

  .select {
    align-self: center;
    height: 100%;
    background: $colorMainYellow;
    border: none;
    outline: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    padding-right: 1.5rem;
  }
  .cameraError{
    color: $colorError;
    align-self: center;
    margin: 10px;
  }
}
