@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.joinDialog{
  @include absoluteCenterBlock;
  min-width: 300px;

  text-align: center;

  .inner{
    padding: 20px;
    background: $colorMainYellow;
    border-radius: 5px;
  }

  span{
    color:$colorMainBlue;
    margin-bottom: 10px;
  }

  input[type='submit']{
    @include button;
  }

  input, input[type='submit']{
    display: block;
    width: 100%;
    margin: 5px 0;
    height: 30px;
    background: transparent;
    outline: none;
    border: 1px solid $colorMainBlack;
    color: $colorMainBlue;
  }


}
