@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.trayButton{
    @include button;
    background-color: transparent;
    margin: 0 5px;
    transition: color 200ms ease, opacity 200ms ease;
    color: inherit;
    &:hover{
        opacity: 0.6;
    }
    &.active{
        color: $colorError;
    }

}
