@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

:fullscreen.tile, :fullscreen.tile.small{
  width:100vw;
}


.tile {
  max-height: 100%;
  margin-right: 10px;
  position: relative;
  font-size: 40px;
  overflow: hidden;
  &.mirror video {
    transform: scale(-1, 1);
  }

  .background {
    background-color: $colorMainBlue;
    width: 100%;
    padding-top: 56.25%; /* Hard-coded 16:9 aspect ratio */
  }
  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0px;
    transition: transform 200ms ease;
  }
  &.small {
    width: 200px;
    font-size: initial;
  }
  .participentInfo {
    font-size: initial;
    position: absolute;
    top: 0;
    right: 0;
    color: $colorMainYellow;
    background: $colorMainBlack;
    opacity: 0.7;
    padding: 2px 5px;
    border-radius: 0 0 0 5px;
  }

  .statusIcons {
    @include absoluteCenterBlock;
    color: $colorMainYellow;
  }

  .tray{
    font-size: initial;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $colorMainYellow;
    background: $colorMainBlack;
    opacity: 0.7;
    padding: 2px 5px;
    border-radius: 5px 0 0 0;
  }
}
