@import './styles/variables.scss';

.app{
    // width: 100vw;
    height: 100%;
    // min-height: -webkit-fill-available;
    background: $gradientMain, url('./img/logo.svg');
    overflow: hidden;
    position: relative;
}
