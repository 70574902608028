@import '../../styles/mixins.scss';

.spinner{
    @include absoluteCenterBlock;
    max-width: 70%;
    max-height: 70%;
    animation: loading 2s infinite;
    &.noAnimation{
      animation: none;
    }
}

@keyframes loading {
	0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
	}

	70% {
        transform: translate(-50%, -50%) scale(1.3);
        opacity: 0.8;

	}

	100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;

	}
}
