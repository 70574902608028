@import './variables.scss';

@mixin absoluteCenterBlock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin button {
  appearance: none;
  outline: none;
  font-size: 20px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: $colorMainYellow;
}
