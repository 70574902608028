@import '../../../../styles/variables.scss';


.audioPlayer{
  min-height:  40px;
  border: 1px solid $colorMainYellow;
  color: $colorMainYellow;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms ease;
  background: none;
  outline: none;
  flex:1;
  margin: 2px;
  &:hover{
    background: rgba(0, 0, 0, 0.5);
  }
}
