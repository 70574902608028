@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.audioPlayer {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease;
  padding: 5px;
  width: 200px;
  right: -200px;

  &.open{
    right: 0;
  }
  .panel{
    @include button;
    position: absolute;
    left: -40px;
    top: calc(50% - 20px);
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 5px 0 0 5px;

  }

  .tracks{
    height: calc(100% - 80px);
    overflow: auto;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
  }
  .uploader{
    height:  80px;
    border: 2px dashed $colorMainYellow;
    color: $colorMainYellow;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms ease;
    background: none;
    &:hover{
      background: rgba(0, 0, 0, 0.5);
    }
    input {
      display: none
    }
  }
}
