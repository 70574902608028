.call {
    position: relative;
    height: calc(100% - 50px);
}

.ownerTiles {
    height: calc(100% - 132.5px);
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    align-items: center;
    overflow-y: scroll;
  }
  
  .gamerTiles {
    height: 132.5px; /* Video height + 10px padding either side */
    display: flex;
    align-items: center;
  }



// .gamerTiles{
//     height: 100px;
//     position: absolute;
//     bottom: 54px;
//     display: flex;
//     right: 10px;
//     left: 10px;

// }